* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: var(--font-family);

  /* overflow-x: hidden; */
}

body, html {
  overflow-x: hidden;

}

a {
    color: unset;
    text-decoration: none;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.flex__col {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.blur {
  filter: blur(8px);
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 1050px) {
  .section__padding {
      padding: 3rem 4rem;
  }

  .section__margin {
      margin: 3rem 4rem;
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
      padding: 2rem 3rem;
  }

  .section__margin {
      margin: 2rem 3rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
      padding: 1rem;
  }

  .section__margin {
      margin: 1rem;
  }
}