@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;0,6..12,1000;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800;1,6..12,900;1,6..12,1000&display=swap');

:root {
  --font-family: 'Nunito Sans', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #0060ff 1.84%, #65a0ff 102.67%);
  --gradient-bg: linear-gradient(103.22deg, #0060ff -13.86%, #65a0ff 99.55%);
  
  --primary-blue: #0060ff;
  --primary-blue-hover: #287afe;
  --secondary-blue: #74a9ff;
  --background-light: #d6e3f3;
  --background-dark: #00142d;
  --color-subtext: #FF8A71;
  --primary-green: #17960e;
  --primary-red: #af1515;

  --dashboard-line: #aaaaaa50;
}
